/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* ======== google fonts ======== */
/* @import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;300;400;600;700;900&display=swap"); */
a.carousel-control-prev{
  display: none !important;
}
.modal.show .modal-dialog{
  position: absolute !important;
  width: 100% !important;
  left: 0 !important;
  right: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
select{
  -webkit-appearance: none;
}

@import url("https://fonts.googleapis.com/css2?family=Cabin:wght@400;500;600;700&display=swap");
/* ======= base style ====== */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Cabin", sans-serif;
}

html {
  /* font-size: 10px; */
  /* 10px / 16px = 0.625 = 62.5% */
  /* Percentage of user's browser font-size setting */
  font-size: 62.5%;
  overflow-x: hidden;
}

/* ========= pre-define css ========= */
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}

h3,
h4 {
  font-size: 1.6rem;
}

h5,
h6 {
  font-size: 1.2rem;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  margin: 0;
  list-style: none;
}

@media only screen and (max-width: 900px) {
  .mobile-view {
    display: none;
  }
  .social-hide-tablet {
    display: none !important;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.btn:hover {
  background-color: rgba(0, 0, 0, 0.9);
  color: #fff;
}
.btn:active {
  background-color: rgba(0, 0, 0, 0.9) !important;
  color: #fff !important;
}

.accordion-item {
  border: none;
}

.accordion-button {
  list-style: none;
  padding: 14px 0 14px 20px;
  border-radius: 14px;
  font-size: 1.8rem;
  line-height: 1.2;
  padding-left: 0;
  padding: 0;
}
.accordion-button:focus {
  border: none;
  box-shadow: none;
  outline: none;
}

.accordion-button:not(.collapsed) {
  border: none;
  box-shadow: none;
  background-color: transparent;
}
.mobile-header {
  display: none !important;
}
.desktop-header {
  display: flex;
}
.main-content {
  padding: 3.2rem 2.4rem;
}
@media only screen and (max-width: 992px) {
  .desktop-header {
    display: none !important;
  }
  .mobile-header {
    display: flex !important;
  }
  .sidear-for-desktop {
    display: none;
  }
}

.fontt-sizee {
  font-size: 18px;
  font-weight: 400;
}

.after-care-heading {
  margin-bottom: 2.8rem;
  font-size: 2.4rem;
  font-weight: 700;
}

.after-treatment-heading {
  margin-bottom: 1.8rem;
  font-size: 2.4rem;
  font-weight: 600;
  color: #4c4d4f;
}

/* Handle */
.progress {
  height: 2px;
}
.progress-bar {
  height: 100%;
  background-color: #4c4d4f !important;
}

.carousel-item {
  margin-top: 1.6rem;
  border-radius: 14px;
  /* background-color: #e1d0c1; */
  background: linear-gradient(
    to right,
    #e1d0c1 0%,
    rgba(225, 208, 193, 0.5) 50%
  );
}

.carousel-caption {
  right: 0;
  bottom: 0;
  left: 0;
  padding-top: 0;
  padding-bottom: 0;
  color: #fff;
  text-align: left;

  top: 20%;
  left: 5%;
}

.carousel-caption h5 {
  font-size: 1.4rem;
  color: #4c4d4f;
  margin-bottom: 1.4rem;
}
.carousel-caption p {
  font-size: 2.5rem;
  color: #4c4d4f;
  margin-bottom: 5.3rem;
}

/* -------------- modal */

.main-modal {
}
.logo {
  width: 45%;
  display: flex;
  margin: 2px auto;
  margin-right: -12px;
}
.modal-header {
  border-bottom: 0;
}
.modal-heading {
  text-align: center;
  font-size: 24px;
}
.modal-container {
  display: flex;
  padding: 25px;
}
@media (max-width: 995px) {
  .modal-container {
    flex-direction: column;
  }
  .colom1 {
    display: block !important;
  }
}
@media (min-width: 995px) {
  .colom12 {
    display: block !important;
  }
}
@media (max-width: 785px) {
  .btns-video {
    overflow: scroll;
  }
}

.modal-container textarea {
  border-radius: 14px;
  height: 109px;
  color: #4c4d4f;
  padding: 0 20px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #4c4d4f33;
  background-color: #4c4d4f33;
  margin-top: 11px;
  font-size: 16px;
  font-weight: 500;
}
.modal-container textarea {
  border-radius: 14px;
  height: 109px;
  color: #4c4d4f;
  padding: 0 20px;
  cursor: pointer;
  width: 100%;
  border: 1px solid #4c4d4f33;
  background-color: #4c4d4f33;
  margin-top: 11px;
  font-size: 16px;
  font-weight: 500;
}
.modal-img {
  width: 100%;
  height: 563px;
}
/* .modal-content {
  width: 210%;
  margin-left: 50%;
} */

@media screen and (max-width: 1460px) {
  .mmkkii {
    flex-direction: column !important;
  }
}
@media screen and (max-width: 1800px) {
  .modal-content {
    margin-left: 34%;
  }
}
@media screen and (max-width: 780px) {
  .TemplateBox {
    min-width: auto !important;
  }
}
@media screen and (max-width: 1180px) {
  .mmp-scroll {
    overflow: scroll;
    width: 100% !important;
    min-width: 100% !important;
    background-color: #fff;
  }
}
@media screen and (max-width: 1550px) {
  .modal-content {
    margin-left: 15%;
  }
}
@media screen and (max-width: 1330px) {
  .modal-content {
    margin-left: auto;
  }
}
@media (max-width: 1224px) {
  .modal-content {
    width: 190%;
  }
}
@media (max-width: 990px) {
  .modal-content {
    width: 170%;
  }
}
@media (max-width: 768px) {
  .modal-content {
    width: 140%;
  }
}
@media (max-width: 751px) {
  .modal-content {
    width: 130%;
  }
  .modal-img {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 691px) {
  .modal-content {
    width: 100%;
  }
}

.modal.show .modal-dialog {
  margin-left: 155px;
}
@media (max-width: 1224px) {
  .modal.show .modal-dialog {
    margin-left: 44px;
  }
}
@media (min-width: 576px) {
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: 0;
    margin-left: 0;
  }
}
.right-text {
  padding: 20px;
}
@media (max-width: 995px) {
  .slider-button {
    display: none;
  }
}
.Addtag {
  height: 47px;
  background-color: #000;
  float: right;
  width: 96px;
  color: white;
  border-radius: 14px;
  border: transparent;
}
.tag-section {
  height: 100%;
  width: 87%;
  border-radius: 14px;
  border: 1px solid #4c4d4f33;
  margin: 0 auto;
  min-width: 620px;
}
.text-area {
  border-radius: 14px;
  height: 109px;
  color: #4c4d4f;
  padding: 0 20px;
  cursor: pointer;
  margin: 0 auto;
  width: 87%;
  border: 1px solid #4c4d4f33;
  background-color: #f8f8f8;
  margin-top: 11px;
  font-size: 16px;
  font-weight: 500;
}
.foot-logo {
  width: 5%;
}
@media (max-width: 691px) {
  .foot-logo {
    width: 12%;
  }
}
@media (max-width: 691px) {
  .footer {
    padding: 19px 11px !important;
  }
}
.footer {
  display: flex;
  gap: 8px;
  padding: 19px 70px;
}

.footer-btn {
  height: 47px;
  background-color: #000;
  float: right;
  width: 96px;
  color: white;
  border-radius: 14px;
  border: transparent;
}
.modal-footer {
  border-top: 0;
}

.responsive-pd {
  display: flex;
}
@media only screen and (max-width: 900px) {
  .responsive-pd {
    flex-direction: column;
  }
}

.left-side {
  width: 70%;
}
@media only screen and (max-width: 900px) {
  .left-side {
    width: 100%;
  }
}

.right-side {
  width: 30%;
}
@media only screen and (max-width: 900px) {
  .right-side {
    width: 100%;
  }
}
@media only screen and (max-width: 900px) {
  .main-table {
    overflow-x: scroll;
  }
}
@media only screen and (max-width: 900px) {
  .faqs-button {
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

@media (max-width: 691px) {
  .closebtn {
    color: #000 !important;
  }
}
@media screen and (max-width: 780px) {
  .col1-resp1 {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 900px) {
  .add-button {
    display: flex;
    margin-top: 11px !important;
    gap: 12px;
    flex-direction: column;
    align-items: center;
  }
}

.TemplateBox {
  display: flex;
  flex-wrap: wrap;
  min-width: 600px;
}
.TemplateBox1 {
  display: flex;
  flex-wrap: wrap;
  background: #fff;
  height: 100%;
}
@media only screen and (max-width: 900px) {
  .col-md-6.col-6 {
    width: 100%;
  }
  .col-xl-8.col-8 {
    width: 100%;
  }
  .col-xl-8.col-8 {
    width: 100%;
  }
  .col-xl-4.col-4 {
    width: 100%;
  }
  .col-md-4.col-4 {
    width: 100%;
    margin-top: 20px;
  }
  .col-xl-9.col-9 {
    width: 100%;
  }
  .col-xl-3.col-3 {
    width: 100%;
    margin-top: 30px;
  }
}

@media only screen and (max-width: 992px) {
  .col-md-6.col-6 {
    width: 100%;
    margin-top: 14px;
  }
  .col-xl-8.col-8 {
    width: 100%;
  }
  .col-xl-8.col-8 {
    width: 100%;
  }
  .col-xl-4.col-4 {
    width: 100%;
  }
  .col-md-4.col-4 {
    width: 100%;
    margin-top: 20px;
  }
  .col-xl-9.col-9 {
    width: 100%;
  }
  .col-xl-3.col-3 {
    width: 100%;
    margin-top: 30px;
  }
  .col-md-8.col-8 {
    width: 100%;
  }
}
.shopping-box {
  padding: 25px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.shopping-box {
  padding: 25px;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

@media screen and (max-width: 420px) {
  .fc-header-toolbar.fc-toolbar {
    flex-wrap: wrap;
  }
  .fc-toolbar-chunk {
    margin-bottom: 30px;
  }
}

.accordion-button.collapsed {
  color: #000;
  background-color: transparent;
}
